var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reporting-files"
  }, [_c('div', {
    staticClass: "reporting-files__container"
  }, [_c('div', {
    staticClass: "reporting-files__column"
  }, [_c('div', {
    staticClass: "files-table"
  }, [_c('div', {
    staticClass: "files-table__inner"
  }, [_c('b-table', {
    ref: "fileTable",
    staticClass: "table sygni-b-table",
    attrs: {
      "show-empty": true,
      "innertable": "true",
      "fields": _vm.fileTableFields,
      "items": _vm.fileTableItems
    },
    scopedSlots: _vm._u([{
      key: "cell(category)",
      fn: function fn(rowData) {
        return [_c('sygni-select', {
          attrs: {
            "options": _vm.selectOptions,
            "disabled": !_vm.editMode,
            "value": rowData.item.category
          },
          on: {
            "input": function input($event) {
              return _vm.selectHandler($event, rowData);
            }
          }
        })];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "td__actions"
        }, [_c('div', {
          staticClass: "action",
          on: {
            "click": function click($event) {
              return _vm.downloadFileByUrl(rowData.item);
            }
          }
        }, [_c('div', {
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('DOWNLOAD')
          }
        })])]), _c('div', {
          staticClass: "action action--delete",
          on: {
            "click": function click($event) {
              return _vm.deleteFile(rowData.item);
            }
          }
        }, [_c('div', {
          staticClass: "action__el"
        }, [_c('svg', {
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "15.605",
            "height": "18",
            "viewBox": "0 0 15.605 18"
          }
        }, [_c('path', {
          staticStyle: {
            "fill": "currentColor"
          },
          attrs: {
            "d": "M8.188.529a1.8 1.8 0 0 0-1.273.528l-.672.672H3.389a1.807 1.807 0 0 0-1.351.576 1.829 1.829 0 0 0-.453 1.224 1.83 1.83 0 0 0 .453 1.225 1.717 1.717 0 0 0 .749.48v1.895a.6.6 0 1 0 1.2 0V5.33h10.8v11.4a.586.586 0 0 1-.6.6h-9.6a.586.586 0 0 1-.6-.6v-6a.6.6 0 1 0-1.2 0v6a1.809 1.809 0 0 0 1.8 1.8h9.6a1.809 1.809 0 0 0 1.8-1.8V5.235a1.717 1.717 0 0 0 .75-.481 1.83 1.83 0 0 0 .453-1.225 1.829 1.829 0 0 0-.453-1.224 1.807 1.807 0 0 0-1.351-.576h-2.853l-.673-.672a1.8 1.8 0 0 0-1.272-.528zm0 1.2h2.4a.6.6 0 0 1 .424.176l.848.848a.6.6 0 0 0 .424.176h3.1a.533.533 0 0 1 .455.174.685.685 0 0 1 0 .853.533.533 0 0 1-.455.174h-12a.533.533 0 0 1-.455-.174.685.685 0 0 1 0-.853.533.533 0 0 1 .455-.174h3.1a.6.6 0 0 0 .424-.176l.848-.848a.6.6 0 0 1 .432-.176zM6.978 7.72a.6.6 0 0 0-.591.609v6a.6.6 0 1 0 1.2 0v-6a.6.6 0 0 0-.609-.609zm2.4 0a.6.6 0 0 0-.591.609v6a.6.6 0 0 0 1.2 0v-6a.6.6 0 0 0-.609-.609zm2.4 0a.6.6 0 0 0-.591.609v6a.6.6 0 0 0 1.2 0v-6a.6.6 0 0 0-.609-.609z",
            "transform": "translate(-1.585 -.529)"
          }
        })])])])])];
      }
    }, {
      key: "empty",
      fn: function fn() {
        return [_c('p', [_vm._v("No files uploaded yet")])];
      },
      proxy: true
    }])
  })], 1)])]), _vm.editMode ? _c('div', {
    staticClass: "reporting-files__column"
  }, [_c('sygni-droparea', {
    on: {
      "change": function change($event) {
        return _vm.addFiles($event);
      }
    }
  })], 1) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }