var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section-table"
  }, [_c('b-table', {
    attrs: {
      "fields": _vm.tableFields,
      "items": _vm.tableItems,
      "tbody-tr-class": _vm.rowClass
    },
    scopedSlots: _vm._u([{
      key: "head()",
      fn: function fn(head) {
        var _head$field;

        return [_c('div', {
          staticClass: "section-table__header text-right"
        }, [_c('p', [_vm._v(_vm._s(head === null || head === void 0 ? void 0 : head.label))]), _c('p', [_vm._v(_vm._s(head === null || head === void 0 ? void 0 : (_head$field = head.field) === null || _head$field === void 0 ? void 0 : _head$field.labelInfo))])])];
      }
    }, {
      key: "cell(name)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell name"
        }, [_vm._v(" " + _vm._s(rowData.item.name) + " ")])];
      }
    }, {
      key: "cell()",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "cell text-right"
        }, [_vm._v(" " + _vm._s(rowData.value) + " ")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }