










































import Vue from 'vue'
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { BTable } from 'bootstrap-vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniDroparea from '@/components/inputs/SygniDroparea.vue';
import Utils, { UploadProgress } from '@/modules/shared/utils/utils';
import { AxiosRequestConfig } from 'axios';
import { BACKEND_BASE_URL } from '@/shared/consts';

@Component({
  components: { BTable, SygniSelect, SygniDroparea }
})
export default class ReportingFilesTable extends Vue {
  @Prop() fileTableData: any;
  @Prop() options: any[];
  @Prop() editMode: boolean;

  uploadProgress: UploadProgress = { progress: -1 };
  areFilesLoading: boolean = false;
  files: any[];

  get selectOptions() {
    return this.options.map((el: string) => {
      return { label: el, value: el };
    })
  }

  get fileTableItems() {
    if(this.fileTableData?.files) {
      return this.fileTableData.files;
    }

    return [];
  }

  selectHandler(value: any, rowData: any) {
    rowData.item.category = value ? value : null;
  }

  downloadFileByUrl(file: any) {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.filePath}`, file.fileName);
  }

  deleteFile(file: File) {
    const index = this.fileTableData?.files.findIndex((el: any) => el == file);
    this.fileTableData?.files.splice(index, 1);
  }

  async addFiles(files: FileList) {
    this.files = [];

    Array.from(files).forEach((file: File) => {
      this.files.push(file);
    });

    await this.uploadFiles();
  }

  async uploadFiles() {
    const config: AxiosRequestConfig = Utils.getUploadFileConfig(this.uploadProgress);
    try {
      this.areFilesLoading = true;
      const promises: Array<Promise<any>> = [];
      this.files.forEach(async (file: File) => {
        promises.push(this.$store.dispatch('genprox/uploadFile', { file, config }));
      })

      const uploadedFiles = await Promise.all(promises);
      this.areFilesLoading = false;
      this.$emit('addFiles', uploadedFiles);
    } catch (e) {
      e;
    }
    this.uploadProgress.progress = -1;
  }

  fileTableFields: any[] = [
    { key: 'fileName', sortable: false, borderless: true, class: 'table__head-cell text-left left' },
    { key: 'category', sortable: false, borderless: true, class: 'table__head-cell category' },
    { key: 'actions', sortable: false, borderless: true, class: 'table__head-cell actions' },
  ];
}
