




























































































import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import SygniPlainInput from '@/components/inputs/SygniPlainInput.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import { create, all } from 'mathjs'
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
const math = create(all);

@Component({
  components: { SygniRectButton, SygniPlainInput, simplebar }
})
export default class ReportingTable extends Vue {
  @Prop() tableFields: any;
  @Prop() tableSections: any;
  @Prop() editMode: boolean;
  @Prop() selectedCol: number;

  math!: any;

  get showPeriod() {
    return this.tableSections?.filter((el: any) => el?.headers?.length)?.length ? false : true
  }

  createForest(flatdata: any[]) {
    const path: any = [{ children: [] }];
    const depthMap: any = { 0: 0 };
    for (const obj of flatdata) {
      path[(depthMap[obj.level] ??= path.length) - 1]?.children.push(
        path[depthMap[obj.level]] = { ...obj, children: [] }
      );
    }
    return path[0].children;
  }

  findNestedItem(arr: any, name: string, nestingKey?: string): any {

    // if empty array then return
    if (arr.length == 0) return

    // return element if found else collect all children(or other nestedKey) array and run this function
    return arr.find((d: any) => d.name == name) || this.findNestedItem(arr.flatMap((d: any) => d[nestingKey] || []), name, 'children');
  }

  allChilds(record: any) {
    const childs = [];
    for (let index = 0; index < record.children.length; index++) {
      let child = record.children[index];
      
      if(record?.children.length) {
        child = this.allChilds(child);
      }
      
      childs.push(child);
    }

    return childs;
  }

  getAllChildren(record: any, positionName: string) {
    if (record.children.length === 0) return [record];

    let allChildElements = [];

    for (let i = 0; i < record.children.length; i++) {
      let children: any = this.getAllChildren(record.children[i], positionName);
      if (children) allChildElements.push(...children);
    }

    if(record.name !== positionName) {
      allChildElements.push(record);
    }

    return allChildElements;
  }

  getRowPositionsValue(sectionName: string, positionName: string, index: number) {
    let record: any = null;
    for (const item of this.nestedItems[sectionName]) {
      if(item.name === positionName) {
        record = item;
        break;
      }
    }

    record = (!record) ? this.findNestedItem(this.nestedItems[sectionName], positionName, 'children') : record;

    const allChilds = this.getAllChildren(record, positionName);
    let total = 0;

    allChilds.forEach((child: any) => {
      total = math.number(math.add(math.bignumber(total), math.bignumber(child.values[index]?.value ? math.number(math.bignumber(child.values[index].value)) : 0)));
    });

    return total;
  }

  get nestedItems() {
    const sections: any = {};

    this.tableSections.forEach((section: any) => {
      const items = section.items.map((el: any) => {
        return {...el, children: []};
      })
      sections[section.name] = this.createForest(items);
    })
    
    return sections;
  }

  generateTableHeaders(section: any) {
    const headerFields = [];

    for (let i = 0; i < section?.headers.length; i++) {
      const header = section?.headers[i];
      headerFields.push({
        key: `header${i}`,
        label: this.$options.filters.formatPeriod(header.date, header.period),
        labelInfo: `${header.aggregation} ${header.range ? header.range : ''}`
      })
    }

    return [
      ...headerFields,
    ]
  }

  countDecimals(value: number) {
    if (Math.floor(value) === value) return 0;
    return value?.toString().split(".")[1]?.length || 0;
  }

  updateTableValue(e: any, id: string) {
    let value = e.target.value.replace(/,/g, '.').replace(/ /g, '');
    value = value ? math.number(math.bignumber(value)) : value;

    this.$store.commit('reporting/setReportingTableRecordValue', {
      id,
      value
    })
  }

  get isFundManager() {
    return this.$route.path.includes('fund-manager') ? true : false;
  }

  get viewedId() {
    return this.isPortfolioCompany ? this.viewedCompanyId : this.viewedFundId;
  }

  get viewedFundId() {
    return this.$store.getters['auth/getViewedFundId'];
  }

  get viewedCompanyId() {
    return this.$store.getters['auth/getViewedCompanyId'];
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('/company') ? true : false;
  }
}
