




import { SelectOption } from '@/store/types';
import Vue from 'vue';
import Component from 'vue-class-component';
import SygniSelect from './inputs/SygniSelect.vue';

@Component({
  components: { SygniSelect }
})
export default class SygniContextPicker extends Vue {
  get isPortfolioCompany() {
    return this.$route.path.includes('/company') ? true : false;
  }

  get viewedFundId() {
    return this.$store.getters['auth/getViewedFundId'];
  }

  get viewedCompanyId() {
    return this.$store.getters['auth/getViewedCompanyId'];
  }

  get viewedId() {
    return this.isPortfolioCompany ? this.viewedCompanyId : this.viewedFundId;
  }

  get fundOptions(): Array<SelectOption<string>> {
    const funds = this.$store.getters['auth/getInvestedFunds'];

    if (!funds) return [];

    return funds.map((el: any) => {
      return {
        label: el.name,
        value: el.id
      }
    });
  }

  get companyOptions(): Array<SelectOption<string>> {
    const companies = this.$store.getters['auth/getInvestedCompanies'];

    if(!companies) return [];

    return companies.map((el: any) => {
      return {
        label: el.name,
        value: el.id
      }
    })
  }

  get options(): Array<SelectOption<string>> {
    return this.isPortfolioCompany ? this.companyOptions : this.fundOptions;
  }

  setViewedId(id: string): void {
    if (id) {
      this.$emit('setViewedId', id);
    }
  }
}
