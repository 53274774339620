























import Vue from 'vue';
import Component from 'vue-class-component'
import { BTable } from 'bootstrap-vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: { BTable },
})
export default class SectionTable extends Vue {
  @Prop() tableFields: any;
  @Prop() tableItems: any;

  rowClass(item: any, type: any) {
    if (!item || type !== 'row') return
    let className = item.highlighted ? 'highlighted ' : '';
    if (item.level === 1) {
      className += 'nest-0'
    } else if (item.level === 2) {
      className += 'nest-1'
    } else if (item.level === 3) {
      className += 'nest-2'
    } else if (item.level === 4) {
      className += 'nest-3'
    } else if (item.level === 5) {
      className += 'nest-4'
    } else {
      className += 'nest-5'
    }

    return className;
  }
}
