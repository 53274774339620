





















































import Vue from 'vue';
import Component from 'vue-class-component'
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniMultiSelect from "@/components/inputs/SygniMultiSelect.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniRangeInput from "@/components/inputs/SygniRangeInput.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniButtonsGroup from "@/components/layout/SygniButtonsGroup.vue";
import SygniContextPicker from '@/components/SygniContextPicker.vue';
// import {SelectOption} from "@/store/types";
import SygniCheckableButton from "@/components/buttons/SygniCheckableButton.vue";
import { Prop } from 'vue-property-decorator';
import moment from 'moment';

@Component({
  components: {
    SygniCheckableButton,
    SygniRangeInput,
    SygniButtonsGroup,
    SygniContextPicker,
    SygniDatePicker, SygniContainerTitle, SygniRectButton, SygniSquareButton, SygniSelect, SygniInput, SygniMultiSelect
  },
})
export default class ReportingFilters extends Vue {
  @Prop({default: true}) showActions: boolean;
  @Prop({default: null}) reportCommon: any;
  @Prop({default: null}) report: any;
  @Prop({default: false}) editMode: boolean;
  @Prop() showLePicker: boolean;

  prevLink: any = null;
  nextLink: any = null;

  get scenarioOptions() {
    if(this.reportCommon?.scenarioChoices) {
      return (Object.values(this.reportCommon.scenarioChoices) as string[]).map((value: string) => {
        return { label: value, value: value }
      })
    }

    return [];
  }

  get periodOptions() {
    if(this.reportCommon?.periodChoices) {
      return (Object.values(this.reportCommon.periodChoices) as string[]).map((value: string) => {
        return { label: value, value: value }
      })
    }

    return [];
  }

  get rangeOptions() {
    if(this.reportCommon?.rangeChoices) {
      return (Object.values(this.reportCommon.rangeChoices) as string[]).map((value: string) => {
        return { label: value, value: value }
      })
    }

    return [];
  }

  get prevDate() {
    if(this.report?.globalHeaders[0]?.date) {
      return this.report.globalHeaders[0].date;
    }
    
    return null;
  }

  get nextDate() {
    if(this.report?.globalHeaders[this.report?.globalHeaders.length - 1]?.date) {
      return this.report.globalHeaders[this.report.globalHeaders.length - 1].date;
    }
    
    return null;
  }

  setViewedId(id: string) {
    if (id) {
      this.$emit('setViewedId', id)
    }
  }

  changeScenario(value: string) {
    this.$emit('changeScenario', value);
  }

  changePeriod(value: string) {
    this.$emit('changePeriod', value);
  }

  changeRange(value: string) {
    this.$emit('changeRange', value);
  }

  prevHandler() {
    const dates = this.prepareDates();

    this.$emit('prev', dates);
  }

  nextHandler() {
    const dates = this.prepareDates();

    this.$emit('next', dates);
  }

  prepareDates() {
    const monthsToChange: string = this.report?.periodType === 'MONTH' ? '1' : '3';

    const prevDate = moment(this.prevDate).subtract(monthsToChange, 'months').format('YYYY-MM-DD');
    const nextDate = moment(this.nextDate).add(monthsToChange, 'months').format('YYYY-MM-DD');

    return {
      prevDate,
      nextDate
    }
  }
}
